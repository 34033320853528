<template>
  <v-row justify="space-around">
    <v-col cols="11">
      <v-text-field v-model="form.currentPassword" :label="changePasswordTexts.oldPassword" clearable type="password"/>
    </v-col>
    <v-col cols="11">
      <v-text-field v-model="form.newPassword" :label="changePasswordTexts.newPassword" clearable type="password"/>
    </v-col>
    <v-col cols="11">
      <v-text-field v-model="form.retypeNewPassword" :label="changePasswordTexts.newPassword" clearable
                    type="password"/>
    </v-col>
    <v-col cols="11" class="text-right">
      <v-btn
          class="ma-2"
          outlined
          :color="colorSet.norbitPrimary"
          tile
          :loading="loading"
          @click="saveCustomerPassword"
      >
        <v-icon>mdi-content-save-outline</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import translationMixin from "@/mixins/translationMixin";

export default {
  name: "CustomerChangePasswordForm",
  props: ['customer'],
  mixins: [translationMixin],
  data: () => ({
    loading: false,
    form: {
      currentPassword: null,
      newPassword: null,
      retypeNewPassword: null,
    }
  }),
  computed: {
    changePasswordTexts() {
      return this.getTranslationByKey('userProfile.changePassword');
    },
  },
  methods: {
    saveCustomerPassword() {
      this.form.customer_id = this.$store.state.user.id;
      this.loading = true;
      this.ax.makeCall('PUT', 'customer-password/' + this.$route.params.id, this.form, true)
          .then(() => {
            this.loading = false;
          })
          .catch((error) => {
            this.showError(error.message);
            this.loading = false;
          });
    }
  }
}
</script>

<style scoped>

</style>