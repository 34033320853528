<template>
  <v-app>
    <v-container fluid class="pa-0 pb-0 position-relative">
      <TopBar v-if="$vuetify.breakpoint.mdAndUp"/>
      <TopBarMobile v-else/>
      <v-img :src="prepareImageUrl(randomBanner.file)" class="cursor-pointer background-image "
             @click="makeClick(randomBanner)" v-if="randomBanner && randomBanner.yt_player===0"/>
        <MainContainer/>
      <BottomBar/>
      <SnackBar/>
    </v-container>
    <LoaderOverlay/>
  </v-app>
</template>

<script>

import SnackBar from "@/components/SnackBar";
import TopBar from "@/components/TopBar";
import LoaderOverlay from "@/components/LoaderOverlay";
import MainContainer from "@/components/MainContainer.vue";
import BottomBar from "@/components/Partials/BottomBar.vue";
import bannerMixin from "@/mixins/bannerMixin";
import imageMixin from "@/mixins/imageMixin";
import TopBarMobile from "@/components/TopBarMobile.vue";

export default {
  name: 'App',
  mixins: [bannerMixin, imageMixin],
  components: {TopBarMobile, BottomBar, MainContainer, LoaderOverlay, TopBar, SnackBar},
  data: () => ({
    banners: [],
    randomBanner: null,
  }),
  mounted() {
    this.getBannersToShow(3);
  },
};
</script>

<style scoped>
.background-image {
  z-index: 0;
  position:fixed;
}
</style>