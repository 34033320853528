<template>
  <v-col cols="12" class="text-center mt-2">
    <v-btn
        v-if="product.active && product.stock > 0"
        class="ma-2"
        outlined
        :color="colorSet.norbitPrimary"
        tile
        v-html="basketText"
        @click="addProductToBasket(1)"
    />
  </v-col>
</template>

<script>
import translationMixin from "@/mixins/translationMixin";
import productMixin from "@/mixins/productMixin";
import basketMixin from "@/mixins/basketMixin";

export default {
  name: "ProductBasketButton",
  props: ['product', 'platform', 'category'],
  mixins: [translationMixin, productMixin, basketMixin],
  computed: {
    basketText: function () {
      return this.getTranslationByKey('productModule.toBasket');
    },
  }
}
</script>