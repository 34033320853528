<template>
  <div class="text-center ma-2" v-if="openSnackbar">
    <v-snackbar
        v-model="openSnackbar"
        :color="color"
        elevation="24"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="closeSnackBar"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "SnackBar",
  computed: {
    openSnackbar: {
      get: function () {
        return this.$store.state.snackBar.show;
      },
      set: function (newValue) {
        this.closeSnackBar();
        return newValue;
      }
    },
    text() {
      return this.$store.state.snackBar.text;
    },
    color() {
      return this.$store.state.snackBar.color;
    },
  },
  methods: {
    closeSnackBar() {
      this.$store.commit('setStateParam', {
        name: 'snackBar',
        value: {
          show: false,
          text: '',
          color: this.color
        }
      })
    }
  }
}
</script>

<style scoped>

</style>