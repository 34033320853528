export default {
    methods: {
        getTranslation() {
            if (this.$store.state.translations) {
                return this.$store.state.translations;
            }
            return {}
        },
        getTranslationByKey(key) {
            let translation = this.getTranslation();
            const keyArray = key.split('.');

            keyArray.forEach(innerKey => {
                if (translation[innerKey]) {
                    translation = translation[innerKey];
                }
            })
            return translation ? translation : '--no translation--'
        }
    },
    computed: {
        loadedTranslations() {
            return Object.values(this.getTranslation()).length > 0;
        }
    }
}
