<template>
  <v-dialog v-model="show" max-width="400" @input="$router.push('/')">
    <v-card class="pa-5">
      <v-card-text v-html="dialogText"/>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogSmall",
  props: ['showDialog', 'dialogText'],
  computed: {
    show: {
      get: function () {
        return this.showDialog;
      },
      set: function (newValue) {
        return newValue;
      },
    },
  },
  methods: {
    closeDialog() {
      this.show = false;
    }
  }
}
</script>

<style scoped>

</style>