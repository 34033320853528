export default {
    methods: {
        getMenuByPosition(position, menuName) {
            this.ax.makeCall('GET', 'menu/get-menu-by-positions/' + position)
                .then(response => {
                    this[menuName] = response;
                });
        }
    }
}
