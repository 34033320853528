<template>
  <v-expand-transition>
    <v-row v-if="!!itemsLists && $store.state.showFilters" justify="center">
      <v-col cols="12" sm="3" v-for="(item, index) in itemsLists" :key="'filter' + index">
        <v-select v-if="item.items.length > 0"
                  v-model="selectedFilters[item.itemName]"
                  :items="item.items"
                  item-text="name"
                  item-value="slugged_name"
                  :label="item.label"
                  flat
                  light
                  small-chips
                  :shaped="false"
                  multiple
                  dense
                  :value="[]"
                  :color="colorSet.norbitPrimary"
                  @change="searchByFilters"
                  clearable
        ></v-select>
      </v-col>
    </v-row>
  </v-expand-transition>
</template>

<script>
import translationMixin from "@/mixins/translationMixin";
import routeMixin from "@/mixins/routeMixin";

export default {
  name: "ProductFilters",
  mixins: [translationMixin, routeMixin],
  data: () => ({
    lists: ['platforms', 'categories', 'tags', 'age'],
    filter: {},
    key: Date.now(),
  }),
  methods: {
    searchByFilters() {
      const keys = Object.keys(this.selectedFilters);
      keys.forEach((key) => {
        this.filter[key] = this.selectedFilters[key].toString();
      });
      if (JSON.stringify(this.filter) !== JSON.stringify(this.$route.query)) {
        if (this.$route.name === 'platform'
            && (this.$route.params && (this.$route.params.platform === 'przedsprzedaz' || this.$route.params.platform === 'nowosci'))
        ) {
          this.$router.push({name: this.$route.name, query: this.filter});
        } else {
          this.$router.push({name: 'filter', query: this.filter});
        }
      }
    },
  },
  computed: {
    selectedFilters() {
      let filters = {};
      this.lists.forEach(item => {
        Object.assign(filters, {[item]: []})
      });
      if (this.routeQuery && this.routeQuery.categories) {
        filters.categories = this.routeQuery.categories.split(",");
      }
      if (this.routeQuery && this.routeQuery.platforms) {
        filters.platforms = this.routeQuery.platforms.split(",");
      }
      if (this.routeQuery && this.routeQuery.tags) {
        filters.tags = this.routeQuery.tags.split(",");
      }
      if (this.routeQuery && this.routeQuery.age) {
        filters.age = this.routeQuery.age.split(",");
      }
      return filters;
    },
    itemsLists: function () {
      return this.lists.map((item) => {
        return {
          label: this.getTranslationByKey('filter.' + item),
          items: (!!this.listFromStore && !!this.listFromStore[item]) ? this.listFromStore[item] : {},
          itemName: item,
        }
      });
    },
    listFromStore() {
      return JSON.parse(this.$store.state.config).lists;
    }
  },
}
</script>

<style scoped>

</style>