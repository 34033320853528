<template>
  <v-row justify="center">
    <v-card
        class="mx-auto"
        width="100%"
    >
      <v-card-title>
        <v-row>
          <v-col cols="12" class="text-h6 font-weight-light" v-html="basketTitle"/>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row class="font-weight-bold">
          <v-col cols="6" v-html="itemTitle"/>
          <v-col cols="3" v-html="itemQuantity"/>
          <v-col cols="3" v-html="itemTotalPrice"/>
        </v-row>
        <v-row>
          <v-divider/>
        </v-row>
        <v-row v-for="item in $store.state.basket" :key="item.slugged_name + '_basket'">
          <v-col cols="6" v-html="item.title" @click="openProductPage(item)" class="cursor-pointer"/>
          <v-col cols="3">
            <v-icon small class="cursor-pointer" @click="modifyItemInBasket(item, -1)">mdi-minus-circle-outline
            </v-icon>
            {{ item.quantity }}
            <v-icon small class="cursor-pointer" @click="modifyItemInBasket(item, 1)">mdi-plus-circle-outline</v-icon>
          </v-col>
          <v-col cols="3" v-html="(item.quantity * item.price).toFixed(2)"/>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-divider/>
          </v-col>
          <v-col cols="3" class="offset-6">
            <b>{{ totalPrice }}</b>
          </v-col>
          <v-col cols="3">
            <b>{{ basketValue.toFixed(2) }}</b>
          </v-col>
        </v-row>
        <v-row justify="space-around" v-if="!hideButtons">
          <v-col cols="12" sm="3">
            <v-btn
                color="primary"
                outlined
                class="ma-2 white--text"
                @click="showConfirmQuestion=true"
            >
              {{ cleanBasket }}
              <v-icon
                  right
                  dark
              >
                mdi-trash-can-outline
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <PlaceOrderNextPrevButtons :show-prev="!isLogged" :show-next="true"
                                   v-on:prevStep="$emit('prevStep')"
                                   v-on:nextStep="$emit('nextStep')"
                                   v-if="!hideButtons"
        />
      </v-card-text>

    </v-card>
    <v-dialog
        class="question"
        v-model="showConfirmQuestion"
        max-width="290"
    >
      <v-card>
        <v-card-title>{{ cleanBasketQuestion }}</v-card-title>
        <v-card-actions class="align-content-space-between">
          <v-row no-gutters>
            <v-col cols="6" class="float-left">
              <v-btn small color="primary" @click="clearBasket">
                <v-icon>mdi-check-outline</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="6" class="text-right">
              <v-btn small @click="showConfirmQuestion=false">
                <v-icon>mdi-cancel</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import translationMixin from "@/mixins/translationMixin";
import basketMixin from "@/mixins/basketMixin";
import userMixin from "@/mixins/userMixin";
import PlaceOrderNextPrevButtons from "@/components/Partials/PlaceOrderNextPrevButtons.vue";

export default {
  components: {PlaceOrderNextPrevButtons},
  props: ['hideButtons'],
  data: () => ({
    showBasket: true,
    showConfirmQuestion: false,
  }),
  mixins: [translationMixin, basketMixin, userMixin],
  name: "BasketContent",
  mounted() {
  },
  methods: {
    openProductPage(product) {
      this.$router.push({
        name: 'productPage',
        params: {
          category: product.category,
          platform: product.platform,
          productTitle: product.slugged_name
        }
      })
    },
    closeBasket() {
      this.$router.push({name: 'home'});
    },
    clearBasket() {
      this.$store.commit('setStateParam', {
        name: 'basket',
        value: [],
      })
      sessionStorage.removeItem('basket');
      this.closeBasket();
    }
  },
  computed: {
    basketTitle: function () {
      return this.getTranslationByKey('basket.title');
    },
    cleanBasket: function () {
      return this.getTranslationByKey('basket.cleanBasket');
    },
    totalPrice: function () {
      return this.getTranslationByKey('basket.totalPrice');
    },

    cleanBasketQuestion: function () {
      return this.getTranslationByKey('basket.cleanBasketQuestion');
    },
    itemTitle: function () {
      return this.getTranslationByKey('basket.itemTitle');
    },
    itemList: function () {
      return this.getTranslationByKey('basket.itemList');
    },
    itemQuantity: function () {
      return this.getTranslationByKey('basket.itemQuantity');
    },
    itemTotalPrice: function () {
      return this.getTranslationByKey('basket.itemTotalPrice');
    },
  }
}
</script>

<style scoped>

</style>