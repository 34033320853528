<template>
  <v-dialog
      v-model="displayForm"
      width="500"
      @close="closeForm"
      @keydown.esc="closeForm"
      @click:outside="closeForm"
  >
    <v-card>
      <v-card-title class="text-h5" v-html="title"/>
      <LoginForm v-if="loginForm" v-on:closeForm="closeForm"/>
      <ResetPasswordForm v-if="resetPasswordForm" v-on:closeForm="closeForm"/>
      <RegisterForm v-if="registerForm" v-on:closeForm="closeForm"/>
      <hr/>
      <v-card-actions>
        <v-btn
            small
            class="ma-2"
            :color="colorSet.norbitSecondary"
            tile
            outlined
            v-html="resetPassword"
            @click="turnOnResetPasswordForm"
        />
        <v-btn
            small
            class="ma-2"
            :color="colorSet.norbitSecondary"
            tile
            outlined
            v-html="register"
            @click="turnOnRegisterForm"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import translationMixin from "@/mixins/translationMixin";
import LoginForm from "@/components/Partials/LoginForm.vue";
import loginRegisterResetMixin from "@/mixins/loginRegisterResetMixin";
import ResetPasswordForm from "@/components/Partials/ResetPasswordForm.vue";
import RegisterForm from "@/components/Partials/RegisterForm.vue";

export default {
  name: "LoginRegisterResetForm",
  components: {RegisterForm, ResetPasswordForm, LoginForm},
  props: ['showForm'],
  mixins: [translationMixin, loginRegisterResetMixin],
  data: () => ({
    loginForm: true,
    registerForm: false,
    resetPasswordForm: false,
  }),
  watch: {
    showLoginResetForm: {
      immediate: true,
      handler() {
        if (this.showLoginResetForm) {
          this.turnOnResetPasswordForm();
        }
      }
    },
  },
  computed: {
    title: function () {
      if (this.registerForm) {
        return this.getTranslationByKey('loginRegisterForm.registerTitle');
      }
      if (this.resetPasswordForm) {
        return this.getTranslationByKey('loginRegisterForm.resetPasswordTitle');
      }
      return this.getTranslationByKey('loginRegisterForm.loginTitle');
    },
    displayForm: {
      get: function () {
        return this.showForm;
      },
      set: function (newValue) {
        return newValue;
      }
    },
    showLoginResetForm() {
      return this.$store.state.showLoginResetForm;
    },
  },
  methods: {
    closeForm() {
      this.turnOffRegisterForm();
      this.turnOffResetPasswordForm();
      this.loginForm = true;
      this.$emit('closeLoginForm');
    },
    turnOnRegisterForm() {
      this.turnOffResetPasswordForm();
      this.loginForm = false;
      this.registerForm = true;
    },
    turnOffRegisterForm() {
      this.registerForm = false;
    },
    turnOnResetPasswordForm() {
      this.turnOffRegisterForm();
      this.loginForm = false;
      this.resetPasswordForm = true;
    },
    turnOffResetPasswordForm() {
      this.resetPasswordForm = false;
    }
  }
}
</script>

<style scoped>

</style>