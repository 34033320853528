export default {
    methods: {
        toggleFilters() {
            this.$store.commit('setStateParam', {
                name: 'showFilters',
                value: !this.$store.state.showFilters
            })
        },
    },
    computed: {
        filterButtonText: function () {
            return this.getTranslationByKey('searchPage.filter');
        },
    }
}
