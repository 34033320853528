<template>
  <div class="pa-0">
    <v-app-bar
        app
        height="60"
        class="pt-1"
        flat
        :color="colorSet.norbitPrimary"
    >
      <v-container class="width-1180" v-if="loadedTranslations">
        <v-row>
          <v-col cols="2">
            <v-icon class="cursor-pointer" color="#ffffff" @click="toggleMenu">mdi-menu</v-icon>
          </v-col>
          <v-col cols="5">
            <v-img :src="logo" alt="NORBiT"
                   class="cursor-pointer"
                   @click="goToHomePage"
                   max-width="189"
            />
          </v-col>
          <TopBasket :cols="5" class="pt-3"/>
          <v-col cols="5" class="pt-6" v-if="1==2">
            <v-row no-gutters justify="center">
              <v-col cols="3" v-for="(item, index) in menu" :key="'top_menu_'+index">
                <div
                    class="top-menu position-relative"
                >
                  <div @click="clickOnMenu(item)" class="cursor-pointer">{{ item.name }}</div>
                  <v-icon class="top-menu-icon"
                          :color="item.id===selectedMenu ? colorSet.norbitPrimary : colorSet.norbitSecondary"
                  >mdi-menu-down
                  </v-icon>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-toolbar
        class="mt-15 lower-bar pt-2"
        height="30"
        :color="colorSet.norbitSecondary"
    >
      <v-row class="pa-0">
        <SearchField :cols="6"/>
        <TopLogin :cols="6" class="mt-2"/>
      </v-row>
    </v-toolbar>
    <v-navigation-drawer
        v-model="showMenu"
        absolute
        temporary
    >
      <v-list
          nav
          dense
      >
        <template v-for="(item, index) in menu">
          <v-list-item v-if="item.sub_menu.length === 0" :key="'top_menu_'+index" class="cursor-pointer">
            <v-list-item-title v-text="item.name" @click="clickOnMenu(item)"/>
          </v-list-item>
          <v-list-group v-else :key="'top_menu_'+index" @click="clickOnMenu(item)">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-html="item.name"/>
              </v-list-item-content>
            </template>
            <v-list-item
                v-for="(subItem, subIndex) in item.sub_menu" :key="'sub_menu_'+subIndex"
                link
                class="pl-5"
            >
              <v-list-item-title v-html="subItem.name" @click="clickOnMenu(subItem)"/>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import logo from '@/assets/img/norbit-logo-white.svg'
import translationMixin from "@/mixins/translationMixin";
import menuMixin from "@/mixins/menuMixin";
import SearchField from "@/components/Partials/SearchField.vue";
import TopLogin from "@/components/Partials/TopLogin.vue";
import TopBasket from "@/components/Partials/TopBasket.vue";

export default {
  name: "TopBarMobile",
  components: {TopBasket, TopLogin, SearchField},
  mixins: [translationMixin, menuMixin],
  data: () => ({
    logo,
    menu: [],
    subMenu: [],
    selectedMenu: 0,
    showMenu: false,
  }),
  mounted() {
    this.getMenuByPosition(1, 'menu');
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.show;
    },
    goToHomePage() {
      if (this.$route.name !== 'home') {
        this.$router.push({name: 'home'})
      }
    },
    clickOnMenu(item) {
      this.selectedMenu = item.id;
      if (item.sub_menu.length === 0) {
        this.subMenu = [];
        this.$router.push({path: item.url});
      } else {
        this.subMenu = item.sub_menu;
      }
    }
  }
}
</script>

<style scoped>
.lower-bar {
  position: fixed;
  width: 100%;
  z-index: 2;
}

.top-menu {
  color: #ffffff;
}

.top-menu-icon {
  position: absolute;
  left: 30%;
  bottom: -32px;
}
</style>