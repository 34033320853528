<template>
  <v-dialog
      v-model="dialog"
      max-width="1200"
      persistent
      v-if="$store.state.user && $store.state.user.id"
  >
    <v-card style="overflow-x: hidden">
      <v-row no-gutters class="pt-1">
        <v-col cols="1" offset="11" class="text-center">
          <v-icon class="cursor-pointer" @click="closeProfile">
            mdi-close-circle-outline
          </v-icon>
        </v-col>
      </v-row>
      <v-card-title v-html="orderHistoryTexts.title"/>
      <v-card-text>
        <v-data-table
            :headers="orderHistoryTexts.tableHeaders"
            :items="orders"
            :single-expand="true"
            :expanded.sync="expanded"
            show-expand
            class="elevation-1"
            :items-per-page="-1"
            hide-default-footer
        >

          <template v-slot:[`item.total_price_gross`]="{ item }">
            {{ (item.total_price_gross / 100).toFixed(2) }}
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-1" style="background-color: gray">
              <v-data-table
                  dense
                  hide-default-footer
                  :headers="orderHistoryTexts.tableHeadersDetail"
                  :items="item.details"
                  :items-per-page="-1"
              >
                <template v-slot:[`item.gross`]="{ item }">
                  {{ (item.gross / 100).toFixed(2) }}
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import userMixin from "@/mixins/userMixin";
import translationMixin from "@/mixins/translationMixin";

export default {
  name: "CustomerOrderHistory",
  mixins: [userMixin, translationMixin],
  data: () => ({
    dialog: true,
    orders: [],
    expanded: [],
  }),
  mounted() {
    this.redirectIfNotLogged();
    this.getCustomerOrders();
  },
  methods: {
    getCustomerOrders() {
      this.ax.makeCall('GET', 'customer/orders', null, true)
          .then((response) => {
            this.orders = response;
          });
    },
    redirectIfNotLogged() {
      if (!this.isLogged) {
        this.$router.push({name: 'home'});
      }
    },
    closeProfile() {
      this.$router.push({name: 'home'});
    },

  },
  computed: {
    orderHistoryTexts() {
      return this.getTranslationByKey('order.history');
    },
    currency: function () {
      return this.getTranslationByKey('app.currency');
    },
  }
}
</script>

<style scoped>

</style>