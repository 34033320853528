import Vue from 'vue'
import VueRouter from 'vue-router'
import MainPage from "@/components/Pages/MainPage";
import ProductPage from "@/components/Pages/ProductPage.vue";
import NotFoundPage from "@/components/Pages/NotFoundPage.vue";
import SearchResultPage from "@/components/Pages/SearchResultPage.vue";
import ActivationPage from "@/components/Pages/ActivationPage.vue";
import ResetPasswordForm from "@/components/Partials/ResetPasswordForm.vue";
import CustomerProfile from "@/components/Pages/CustomerProfile.vue";
import CustomerOrderHistory from "@/components/Pages/CustomerOrderHistory.vue";
import ArticlePage from "@/components/Pages/ArticlePage.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/transaction/:orderNumber',
        name: 'transaction',
        component: MainPage,
        props: {
            przelewy24: true,
        }
    },
    {
        path: '/',
        name: 'home',
        component: MainPage,
        children: [
            {
                path: '/artykul/:article',
                name: 'article',
                component: ArticlePage,
            },
            {
                path: 'customer/confirm/:uuid',
                name: 'activation',
                component: ActivationPage,
            },
            {
                path: 'customer/reset-password/:uuid',
                name: 'resetPasswordForm',
                component: ResetPasswordForm,
                props: {
                    step: 2,
                },
            },
            {
                path: '/:platform/:category/:productTitle',
                name: 'productPage',
                component: ProductPage
            },
            {
                path: '/wyszukiwanie',
                name: 'filter',
                component: SearchResultPage,
            },
            {
                path: '/:platform',
                name: 'platform',
                component: SearchResultPage,
            },
            {
                path: 'customer/settings/profile/:id',
                name: 'customerProfile',
                component: CustomerProfile,
            },
            {
                path: 'customer/settings/orderhistory',
                name: 'customerOrderHistory',
                component: CustomerOrderHistory,
            },
        ],
    },
    {
        path: '/basket/content',
        name: 'basket',
        component: MainPage,
        props: {
            showBasket: true,
        },
    },
    {path: "*", component: NotFoundPage}
]

const router = new VueRouter({
    routes,
    mode: "history"
})

export default router
