<template>
  <div v-if="$store.state.config && product">
    <v-row>
      <v-col cols="12" class="mt-5">
        <v-btn v-for="(tag, index) in product.tags"
               x-small
               v-html="tag.name" :key="index"
               tile elevation="0"
               :color="colorSet.norbitPrimary"
               max-height="23"
               class="white--text tag-class mr-1"
        />
      </v-col>
      <v-col cols="12" v-html="product.name" class="product-page-title mt-3"/>
      <v-col cols="12" sm="9" class="mt-3 pr-1">
        <v-card color="#f7f7f7" flat tile class="pa-1">
          <v-row no-gutters>
            <v-col cols="12" sm="4">
              <v-img :src="prepareImageUrl(product.image)" width="100%" class="product-page-image"/>
            </v-col>
            <v-col cols="12" sm="8" class="px-3">
              <v-row>
                <v-col cols="12" v-html="product.content" class="product-page-content"/>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="5" v-html="specifictation.category + ':'" class="py-0 font-weight-bold"/>
                    <v-col cols="7" class="py-0">
                      <span v-for="category in product.categories" :key="'cat_'+category.slugged_name"
                            v-html="category.name"/>
                    </v-col>
                    <v-col v-if="product.languageVersion" cols="5" v-html="specifictation.languageVersion + ':'"
                           class="py-0 font-weight-bold"/>
                    <v-col v-if="product.languageVersion" cols="7" v-html="product.languageVersion" class="py-0"/>
                    <v-col v-if="product.pegis" cols="5" v-html="specifictation.ageCategory + ':'"
                           class="py-0 font-weight-bold"/>
                    <v-col v-if="product.pegis" cols="7" v-html="product.pegis[0].name" class="py-0"/>
                    <v-col v-if="product.note" cols="5" v-html="specifictation.additionalInfo + ':'"
                           class="py-0 font-weight-bold"/>
                    <v-col v-if="product.note" cols="7" v-html="product.note" class="py-0"/>
                    <v-col v-if="product.end_preorder_date" cols="5" v-html="specifictation.premiereText + ':'"
                           class="py-0 font-weight-bold"/>
                    <v-col v-if="product.end_preorder_date" cols="7" class="py-0">{{product.end_preorder_date.slice(0,-9)}}</v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" sm="3" class="mt-3 pl-1">
        <v-card color="#f7f7f7" flat tile class="pa-1" :height="$vuetify.breakpoint.xs ? 220 : '100%'">
          <ProductPricePart :product="product" :productPage="true" :platform="platform" :category="category"/>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import translationMixin from "@/mixins/translationMixin";
import imageMixin from "@/mixins/imageMixin";
import ProductPricePart from "@/components/Partials/ProductPricePart.vue";
import routeMixin from "@/mixins/routeMixin";

export default {
  name: 'ProductPage',
  components: {ProductPricePart},
  mixins: [translationMixin, imageMixin, routeMixin],
  metaInfo() {
    return {
      title: this.product ? this.product.name : '',
      titleTemplate: '%s - NOR-BIT::konsole, gry, akcesoria!',
      htmlAttrs: {
        lang: 'pl-PL',
      },
      meta: [
        {charset: 'utf-8'},
        {name: 'description', content: this.product ? this.product.lead : ''},
      ]
    }
  },
  data: () => ({
    product: null,
    error: null,
  }),
  mounted() {
  },
  watch: {
    sluggedProductTitle: {
      immediate: true,
      handler() {
        this.getProduct();
      },
    },
  },
  computed: {
    sluggedProductTitle() {
      return this.$route.params.productTitle;
    },
    category() {
      return this.routeParams.category ? this.routeParams.category : null;
    },
    platform() {
      return this.routeParams.platform ? this.routeParams.platform : null;
    },
    specifictation() {
      return {
        category: this.getTranslationByKey('productPage.category'),
        languageVersion: this.getTranslationByKey('productPage.languageVersion'),
        ageCategory: this.getTranslationByKey('productPage.ageCategory'),
        additionalInfo: this.getTranslationByKey('productPage.additionalInfo'),
        premiereText: this.getTranslationByKey('productModule.premiere'),
      };
    },
  },
  methods: {
    getProduct() {
      this.showLoader();
      this.ax.makeCall('GET', 'product/' + this.sluggedProductTitle)
          .then(response => {
            if (response.error) {
              this.showError(this.getTranslationByKey('errors.' + response.error))
            } else {
              this.product = response;
            }
            this.hideLoader();
          })
    }
  }
}
</script>

<style scoped>
.tag-class {
  font-size: 10px;
}

.product-page-title {
  font-size: 24px;
  color: #565656;
}

.product-page-image {
  display: inline-block;
}

.product-page-content {
  color: #a6a6a6;
  font-size: 14px;
  text-align: justify;
}
</style>