<template>
  <v-row no-gutters dense>
    <v-col cols="12">
      <v-radio-group
          v-model="paymentType"
      >
        <v-radio
            v-for="(paymentType, index) in paymentTypes" :key="'delivery_type'+index"
            :label="paymentType.name"
            :value="paymentType.id"
            @click="updateOrderPaymentType"
        ></v-radio>
      </v-radio-group>
    </v-col>
    <PlaceOrderNextPrevButtons :show-prev="true" :show-next="!!paymentType"
                               v-on:prevStep="$emit('prevStep')"
                               v-on:nextStep="$emit('nextStep')"
    />
  </v-row>
</template>

<script>
import PlaceOrderNextPrevButtons from "@/components/Partials/PlaceOrderNextPrevButtons.vue";

export default {
  name: "PlaceOrderPayment",
  components: {PlaceOrderNextPrevButtons},
  data: () => ({
    paymentType: null,
    paymentTypes: [],
  }),
  mounted() {
    this.getPaymentTypes();
  },
  methods: {
    updateOrderPaymentType() {
      const selectedPaymentTypes = this.paymentTypes.filter(payemntType => payemntType.id === this.paymentType);
      if (selectedPaymentTypes.length > 0) {
        let order = this.$store.state.order;
        if (order) {
          order = Object.assign(order, {payment: selectedPaymentTypes[0]})
        } else {
          order = {delivery: selectedPaymentTypes[0]};
        }
        this.$store.commit('setStateParam', {
          name: 'order',
          value: null
        })
        this.$store.commit('setStateParam', {
          name: 'order',
          value: order
        })
      }
    },
    getPaymentTypes() {
      this.ax.makeCall('GET', 'payments')
          .then((response) => {
            this.paymentTypes = response;
          });
    }
  }
}
</script>

<style scoped>

</style>