<template>
  <div class="pa-0" v-if="$store.state.config">
    <v-toolbar
        :height="!$vuetify.breakpoint.mobile ? 170 : 'auto'"
        class="pa-0"
        flat
        :color="colorSet.norbitSecondary"
    >
      <v-container class="width-1180">
        <v-row>
          <v-col cols="12" sm="4" class="pa-0">
            <v-img :src="logo" alt="NORBiT"
                   class="cursor-pointer"
                   @click="goToHomePage"
                   max-width="460"
            />
          </v-col>
          <v-col cols="12" sm="4" class="pa-0">
            <ul v-if="menuLeft.length > 0" class="ul-menu">
              <li v-for="(item, index) in menuLeft" :key="'menu_left'+index">
                <v-icon v-if="item.icon">mdi-{{ item.icon }}</v-icon>
                <a :href="item.url" v-html="item.name"/>
              </li>
            </ul>
          </v-col>
          <v-col cols="12" sm="4" class="pa-0">
            <ul v-if="menuRight.length > 0" class="ul-menu">
              <li v-for="(item, index) in menuRight" :key="'menu_left'+index">
                <v-icon v-if="item.icon">mdi-{{ item.icon }}</v-icon>
                <a :href="item.url" v-html="item.name"/>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
    <v-toolbar
        class="lower-bar text-center pt-1"
        height="30"
        :color="colorSet.norbitPrimary"
        v-html="copyRightText"
    >

    </v-toolbar>
  </div>
</template>

<script>
import logo from '@/assets/img/norbit-logo-white.svg'
import translationMixin from "@/mixins/translationMixin";
import menuMixin from "@/mixins/menuMixin";

export default {
  data: () => ({
    logo,
    menuLeft: [],
    menuRight: [],
  }),
  mixins: [translationMixin, menuMixin],
  name: "BottomBar",
  mounted() {
    this.getMenuByPosition(2, 'menuLeft');
    this.getMenuByPosition(3, 'menuRight');
  },
  methods: {
    goToHomePage() {
      if (this.$route.name !== 'home') {
        this.$router.push({name: 'home'})
      }
    },
  },
  computed: {
    copyRightText: function () {
      return this.getTranslationByKey('footer.copy');
    },
  }
}
</script>

<style scoped>
.lower-bar {
  width: 100%;
  z-index: 1;
  color: #ffffff;
  font-size: 13px;
}

.ul-menu {
  list-style: none;
}

.ul-menu a, .ul-menu a:visited, .ul-menu i {
  text-decoration: none;
  color: #ffffff;
}
</style>