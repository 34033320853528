<template>
  <v-row justify="space-around">
    <v-col cols="12" sm="3" v-if="showPrev">
      <v-btn
          color="primary"
          class="ma-2 white--text"
          @click="$emit('prevStep')"
      >
        <v-icon
            right
            dark
            class="mr-1"
        >
          mdi-arrow-left-bold-outline
        </v-icon>
        {{ previousStep }}
      </v-btn>
    </v-col>
    <v-col cols="12" sm="3" v-if="showNext">
      <v-btn
          color="primary"
          class="ma-2 white--text"
          @click="$emit('nextStep')"
      >
        {{ nextStep }}
        <v-icon
            right
            dark
        >
          mdi-arrow-right-bold-outline
        </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import translationMixin from "@/mixins/translationMixin";
import userMixin from "@/mixins/userMixin";

export default {
  name: "PlaceOrderNextPrevButtons",
  props: ['showPrev', 'showNext'],
  mixins: [translationMixin, userMixin],
  computed: {
    nextStep: function () {
      return this.getTranslationByKey('basket.nextStep');
    },
    previousStep: function () {
      return this.getTranslationByKey('basket.previousStep');
    },
  }
}
</script>

<style scoped>

</style>