import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import store from "@/store";

Vue.use(Vuetify);

const opts = {
    theme: {
        themes: {
            light: store.state.leadingColors
        }
    }
}

export default new Vuetify(opts);
