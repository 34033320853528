<template>
  <v-col cols="12" class="text-center mt-2" v-if="page < totalPages">
    <v-btn
        class="ma-2"
        outlined
        :color="colorSet.norbitPrimary"
        tile
        v-html="buttonText"
        @click="$emit('loadMoreProducts')"
    />
  </v-col>
</template>

<script>
import translationMixin from "@/mixins/translationMixin";

export default {
  name: "ProductsLoadMoreButton",
  props: ['page', 'totalPages'],
  mixins: [translationMixin],
  data: () => ({}),
  methods: {},
  computed: {
    buttonText: function () {
      return this.getTranslationByKey('searchPage.loadMore');
    },
  }
}
</script>