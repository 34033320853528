<template>
  <v-row justify="space-around">
    <v-col cols="11">
      <v-text-field v-model="customerAddress.address" :label="addressDataTexts.address" clearable/>
    </v-col>
    <v-col cols="11">
      <v-text-field v-model="customerAddress.house_number" :label="addressDataTexts.houseNo" clearable/>
    </v-col>
    <v-col cols="11">
      <v-text-field v-model="customerAddress.flat_number" :label="addressDataTexts.flatNo" clearable/>
    </v-col>
    <v-col cols="11">
      <v-text-field v-model="customerAddress.zip" :label="addressDataTexts.zip" clearable/>
    </v-col>
    <v-col cols="11">
      <v-text-field v-model="customerAddress.city" :label="addressDataTexts.city" clearable/>
    </v-col>
    <v-col cols="11">
      <v-text-field v-model="customerAddress.country" :label="addressDataTexts.country" clearable/>
    </v-col>
    <v-col cols="11" class="text-right">
      <v-btn
          class="ma-2"
          outlined
          :color="colorSet.norbitPrimary"
          tile
          :loading="loading"
          @click="saveCustomerAddressData"
      >
        <v-icon>mdi-content-save-outline</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import translationMixin from "@/mixins/translationMixin";

export default {
  name: "CustomerAddressForm",
  props: ['customer'],
  mixins: [translationMixin],
  data: () => ({
    loading: false,
  }),
  computed: {
    addressDataTexts() {
      return this.getTranslationByKey('userProfile.addressData');
    },
    customerAddress: {
      get: function () {
        if (this.customer.address_data) {
          return this.customer.address_data;
        }
        return {
          address: null,
          house_number: null,
          flat_number: null,
          zip: null,
          city: null,
          country: 'Polska',
        }
      },
      set: function (newValue) {
        return newValue
      }
    }
  },
  methods: {
    saveCustomerAddressData() {
      this.customerAddress.customer_id = this.$store.state.user.id;
      this.loading = true;
      this.ax.makeCall('PUT', 'customer-address/' + this.$route.params.id, this.customerAddress, true)
          .then(() => {
            this.loading = false;
          });
    }
  }
}
</script>

<style scoped>

</style>