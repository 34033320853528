<template>
  <v-dialog
      v-model="showQuestion"
      max-width="290"
  >
    <v-card>
      <v-card-title v-html="question"/>
      <v-card-actions class="align-content-space-between">
        <v-row no-gutters>
          <v-col cols="6" class="float-left">
            <v-btn small color="primary" @click="$emit('confirm')">
              <v-icon>mdi-check-outline</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn small @click="$emit('cancel')">
              <v-icon>mdi-cancel</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogConfirm",
  props: ['showConfirmQuestion', 'question'],
  data: () => ({
  }),
  computed: {
    showQuestion: {
      get: function () {
        return this.showConfirmQuestion;
      },
      set: function (newValue) {
        return newValue;
      }
    }
  }
}
</script>

<style scoped>

</style>