<template>
  <div v-if="$store.state.config">
    <TopBanersSlider/>
    <router-view></router-view>
    <v-row no-gutters class="mt-5 pl-2">
      <v-col cols="10">
        <ProductFilters/>
      </v-col>
      <v-col cols="2" class="text-right">
        <v-btn outlined tile :color="colorSet.norbitPrimary" @click="toggleFilters">
          <v-icon>mdi-filter-outline</v-icon>
          {{ filterButtonText }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-2 pl-2" v-if="hasConfig && !hideProductModules">
      <v-col cols="12" sm="6">
        <ProductsModule :moduleData="newestProducts"/>
      </v-col>
      <v-col cols="12" sm="6">
        <ProductsModule :moduleData="preorderProducts" :showSquare="true"/>
      </v-col>
      <v-col cols="12" sm="6">
        <ProductsModule :moduleData="lastProducts"/>
      </v-col>
      <v-col cols="12" sm="12">
        <ProductsModule :moduleData="popularProducts" :showFullRow="true"/>
      </v-col>
      <v-col cols="12">
        <ProductsModule :moduleData="gadgets" :isGadget="true"/>
      </v-col>
      <PlaceOrder v-if="showBasket"/>
      <DialogSmall v-if="przelewy24" :show-dialog="showDialog" :dialog-text="orderText.przelewy24processed"/>
    </v-row>
  </div>
</template>

<script>
import TopBanersSlider from "@/components/Partials/TopBanersSlider.vue";
import ProductsModule from "@/components/Partials/ProductsModule.vue";
import PlaceOrder from "@/components/Partials/PlaceOrder.vue";
import ProductFilters from "@/components/Partials/ProductFilters.vue";
import filterMixin from "@/mixins/filterMixin";
import translationMixin from "@/mixins/translationMixin";
import DialogSmall from "@/components/DialogSmall.vue";
import routeMixin from "@/mixins/routeMixin";

export default {
  name: 'MainPage',
  components: {DialogSmall, ProductFilters, PlaceOrder, ProductsModule, TopBanersSlider},
  props: ['setNewPasswordForm', 'showBasket', 'przelewy24'],
  mixins: [filterMixin, translationMixin, routeMixin],
  data: () => ({
    overlay: false,
    zIndex: 0,
    newestProducts: [],
    preorderProducts: [],
    lastProducts: [],
    popularProducts: [],
    gadgets: [],
  }),
  metaInfo() {
    return {
      title: '',
      titleTemplate: '%s - ' + this.siteInfo.site_title,
      htmlAttrs: {
        lang: 'pl-PL',
      },
      meta: [
        {charset: 'utf-8'},
        {name: 'description', content: this.siteInfo.meta_description},
        {name: 'keywords', content: this.siteInfo.meta_keywords},
      ]
    }
  },
  mounted() {
    this.showLoader();
    if (!sessionStorage.key('config')) {
      this.ax.makeCall('GET', 'config/' + this.state.lang).then(response => {
        sessionStorage.setItem('config', JSON.stringify(response));
        this.$store.commit('setStateParam', {
          name: 'config',
          value: JSON.stringify(response),
        })
        if (response.translation) {
          this.$store.commit('setStateParam', {
            name: 'translations',
            value: response.translation,
          })
        }
        this.hideLoader();
      });
    } else {
      this.$store.commit('setStateParam', {
        name: 'config',
        value: sessionStorage.getItem('config'),
      })
      this.$store.commit('setStateParam', {
        name: 'translations',
        value: JSON.parse(sessionStorage.getItem('config')).translation,
      })
      this.hideLoader();
    }

  },
  computed: {
    showDialog: {
      get: function () {
        return this.przelewy24;
      },
      set: function (newValue) {
        return newValue;
      }
    },
    orderText() {
      return this.getTranslationByKey('order');
    },
    hideProductModules() {
      return this.$route.name === 'platform'
    },
    hasConfig() {
      if (this.$store.state.config !== null) {
        this.getProducts();
        return true;
      }
      return false;
    },
    siteInfo() {
      if (this.$store.state.config) {
        return JSON.parse(this.$store.state.config).siteInfo;
      }
      return {
        site_title: '',
        meta_description: '',
        meta_keywords: ''
      };
    }
  },
  methods: {
    getProducts() {
      this.ax.makeCall('GET', 'main/get-products')
          .then(response => {
            this.newestProducts = response.newestProducts;
            this.preorderProducts = response.preorderProducts;
            this.lastProducts = response.lastProducts;
            this.popularProducts = response.popularProducts;
            this.gadgets = response.gadgets;
          })
    }
  }
}
</script>
