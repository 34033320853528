<template>
  <v-col :cols="!!cols ? cols : 6">
    <v-text-field
        v-model="searchValue"
        dense
        background-color="#ffffff"
        class="mt-1"
        @keyup.enter="search"
        :height="!!cols ? 20 : 24"
    >
      <template slot="append-outer">
        <v-icon color="#ffffff" class="cursor-pointer magnify-icon" @click="search">mdi-magnify</v-icon>
      </template>
    </v-text-field>
  </v-col>
</template>

<script>
import routeMixin from "@/mixins/routeMixin";

export default {
  name: "SearchField",
  props: ['cols'],
  mixins: [routeMixin],
  data: () => ({
    searchValue: null,
  }),
  watch: {
    routeQuery: {
      immediate: true,
      handler() {
        if (!this.routeQuery || !this.routeQuery.search) {
          this.searchValue = null;
        }
      }
    }
  },
  methods: {
    search() {
      if (this.searchValue.length >= 3) {
        this.$router.push({name: 'filter', query: {search: this.searchValue}});
      }
    }
  }
}
</script>

<style scoped>
.magnify-icon {
  margin-top: -3px;
}
</style>