<template>
  <div>
    <v-card-text>
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
      >
        <template v-if="!showLoginResetForm">
          <v-text-field
              v-model="form.email"
              :rules="emailRules"
              :label="emailInput"
          />
          <v-text-field
              v-model="form.repeatEmail"
              :rules="repeatEmail"
              :label="repeatEmailInput"
          />
        </template>
        <template v-else>
          <v-text-field
              v-model="form.password"
              :rules="passwordRules"
              :label="passwordInput"
              :type="'password'"
          />
          <v-text-field
              v-model="form.password2"
              :rules="passwordRepeatRules"
              :label="repeatPasswordInput"
              :type="'password'"
          />
        </template>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
          class="ma-2"
          :color="colorSet.norbitPrimary"
          tile
          v-html="buttonResetPassword"
          @click="activateForm"
          :disabled="!valid"
      />
      <v-btn
          class="ma-2"
          outlined
          :color="colorSet.norbitPrimary"
          tile
          v-html="buttonCancel"
          @click="$emit('closeForm')"
      />
    </v-card-actions>
  </div>
</template>

<script>
import loginRegisterResetMixin from "@/mixins/loginRegisterResetMixin";
import translationMixin from "@/mixins/translationMixin";

export default {
  name: "ResetPasswordForm",
  mixins: [translationMixin, loginRegisterResetMixin],
  props: ['step'],
  data: () => ({
    valid: true,
    form: {
      email: null,
      repeatEmail: null,
      password: null,
      password2: null,
    },
  }),
  mounted() {
    this.validate();
    if (this.uuid) {
      this.verifyResetPasswordLink();
    }
  },
  methods: {
    activateForm() {
      if (this.showLoginResetForm) {
        this.setNewPassword();

      } else {
        this.startResetPasswordProcedure();
      }
    },
    startResetPasswordProcedure() {
      this.showLoader();
      this.$emit('closeForm');
      this.ax.makeCall('POST', 'customer/reset-password', this.form)
          .then((response) => {
            this.hideLoader();
            this.showSuccess(response.message);
          })
          .catch((response) => {
            this.hideLoader();
            this.showError(response.message);
          });
    },
    verifyResetPasswordLink() {
      this.showLoader();
      this.ax.makeCall('POST', 'customer/reset-password-verify', {uuid: this.uuid})
          .then(() => {
            this.hideLoader();
            this.$store.commit('setStateParam', {
              name: 'showLoginResetForm',
              value: this.uuid,
            })
            this.$router.replace('/');
          })
          .catch(() => {
            this.hideLoader();
            this.$router.replace('/');
          });
    },
    setNewPassword() {
      this.showLoader();
      this.ax.makeCall('POST', 'customer/reset-password-set-new', {
        password: this.form.password,
        password2: this.form.password2,
        uuid: this.showLoginResetForm
      })
          .then((response) => {
            this.hideLoader();
            this.showSuccess(response);
            this.$store.commit('setStateParam', {
              name: 'showLoginResetForm',
              value: null,
            })
            this.$router.replace('/');
            this.$emit('closeForm');
          })
          .catch((error) => {
            this.hideLoader();
            this.showError(error)
          });
    }
  },
  computed: {
    uuid() {
      return this.$route.params.uuid;
    },
    showLoginResetForm() {
      return this.$store.state.showLoginResetForm;
    },
  }
}
</script>

<style scoped>

</style>