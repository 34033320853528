<template>
  <v-row justify="center">
    <v-dialog
        v-model="showBasket"
        persistent
        max-width="1000"
        v-if="$store.state.basket.length>0"
    >
      <v-stepper
          v-model="orderStep"
      >
        <v-row justify="end">
          <v-col cols="8" sm="3">
            <v-btn
                color="primary"
                outlined
                class="ma-2 white--text"
                @click="$router.push({name: 'home'})"
            >
              {{ exitBasket }}
              <v-icon class="cursor-pointer">
                mdi-exit-to-app
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="pa-5" dense>
          <v-col cols="12" sm="4">
            <b>{{ orderText.orderPrice }}:</b> {{ basketValue.toFixed(2) }} {{ currency }}
          </v-col>
          <v-col cols="12" sm="4">
            <b>{{ orderText.deliveryPrice }}:</b> {{ $store.state.order.delivery.gross }} {{ currency }}
          </v-col>
          <v-col cols="12" sm="4">
            <b>{{ orderText.totalPrice }}:</b> {{ (basketValue + $store.state.order.delivery.gross).toFixed(2) }}
            {{ currency }}
          </v-col>
        </v-row>


        <v-stepper-header>
          <template v-if="!isLogged">
            <v-stepper-step :complete="orderStep > 0" step="0">{{ orderText.login }}</v-stepper-step>
            <v-divider/>
          </template>
          <template v-for="(orderStepText, index) in orderText.orderSteps">
            <v-stepper-step
                :key="'stepHeader_'+index"
                :complete="orderStep > parseInt(index)"
                :step="index"
            >{{ orderStepText }}
            </v-stepper-step>
            <v-divider :key="'stepHeader_divider_'+index"/>
          </template>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="0">
            <LoginForm/>
            <v-row justify="space-around">
              <v-col cols="12" sm="3" offset-sm="8">
                <v-btn
                    color="primary"
                    class="ma-2 white--text"
                    @click="nextStep"
                >
                  {{ orderText.buyWithoutLogin }}
                  <v-icon
                      right
                      dark
                  >
                    mdi-arrow-right-bold-outline
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content step="1">
            <BasketContent v-on:nextStep="nextStep" v-on:prevStep="prevStep"/>
          </v-stepper-content>
          <v-stepper-content step="2">
            <PlaceOrderAddress v-on:nextStep="nextStep" v-on:prevStep="prevStep"/>
          </v-stepper-content>
          <v-stepper-content step="3">
            <PlaceOrderDelivery v-on:nextStep="nextStep" v-on:prevStep="prevStep"/>
          </v-stepper-content>
          <v-stepper-content step="4">
            <PlaceOrderPayment v-on:nextStep="nextStep" v-on:prevStep="prevStep"/>
          </v-stepper-content>
          <v-stepper-content step="5">
            <PlaceOrderSummary v-on:nextStep="nextStep" v-on:prevStep="prevStep"/>
          </v-stepper-content>
        </v-stepper-items>

      </v-stepper>
    </v-dialog>
  </v-row>
</template>

<script>
import translationMixin from "@/mixins/translationMixin";
import BasketContent from "@/components/Partials/BasketContent.vue";
import basketMixin from "@/mixins/basketMixin";
import userMixin from "@/mixins/userMixin";
import LoginForm from "@/components/Partials/LoginForm.vue";
import PlaceOrderDelivery from "@/components/Partials/PlaceOrderDelivery.vue";
import PlaceOrderPayment from "@/components/Partials/PlaceOrderPayment.vue";
import PlaceOrderSummary from "@/components/Partials/PlaceOrderSummary.vue";
import PlaceOrderAddress from "@/components/Partials/PlaceOrderAddress.vue";

export default {
  components: {PlaceOrderAddress, PlaceOrderSummary, PlaceOrderPayment, PlaceOrderDelivery, LoginForm, BasketContent},
  data: () => ({
    showBasket: true,
    orderStep: 0
  }),
  watch: {
    isLogged: {
      immediate: true,
      handler() {
        if (!this.isLogged) {
          this.orderStep = 0;
        } else {
          this.orderStep = 1;
        }
      },
      deep: false
    }
  },
  mixins: [translationMixin, basketMixin, userMixin],
  name: "PlaceOrder",
  mounted() {
    if (this.showBasket && this.$store.state.basket.length === 0) {
      this.$router.push('/');
    }
  },
  methods: {
    nextStep() {
      this.orderStep++;
    },
    prevStep() {
      this.orderStep--;
    }
  },
  computed: {
    orderText: function () {
      return this.getTranslationByKey('order');
    },
    exitBasket: function () {
      return this.getTranslationByKey('basket.exitBasket');
    },
    currency: function () {
      return this.getTranslationByKey('app.currency');
    },
  }
}
</script>

<style scoped>

</style>