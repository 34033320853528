<template>
  <v-sheet
      class="mx-auto pa-0 ma-0 background-transparent-important overflow-hidden"
  >
    <v-slide-group
        multiple
        show-arrows
        class="pa-0 ma-0 position-relative top-slider"
    >
      <template v-slot:prev>
        <v-icon large class="arrow-left">mdi-chevron-left</v-icon>
      </template>
      <template v-slot:next>
        <v-icon large class="arrow-right">mdi-chevron-right</v-icon>
      </template>
      <v-slide-item
          v-for="(banner, index) in banners"
          :key="'top_banner'+index"
          class="ml-2 mr-2"
      >
        <v-card class="banner-card-bg px-1 pt-1" color="#f7f7f7" flat tile>
          <v-img :src="prepareImageUrl(banner.file)" width="270" height="135" class="cursor-pointer"
                 @click="makeClick(banner)"/>
        </v-card>
      </v-slide-item>
    </v-slide-group>
  </v-sheet>
</template>

<script>
import bannerMixin from "@/mixins/bannerMixin";
import imageMixin from "@/mixins/imageMixin";

export default {
  name: "TopBanersSlider",
  mixins: [bannerMixin, imageMixin],
  data: () => ({
    currentBanner: 0,
    banners: []
  }),
  mounted() {
    this.getBannersToShow(1);
  },
  methods: {}
}
</script>

<style scoped>
.arrow-left {
  position: absolute;
  left: 9px;
}

.arrow-right {
  position: absolute;
  right: 4px;
}

.banner-card-bg {
  width: 276px;
  height: 138px
}

.banner-image {
  width: 270px;
  height: 130px;
}
</style>