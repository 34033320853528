import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './assets/css/main.css';
import axios from 'axios'
import VueAxios from 'vue-axios'
import Axios from "@/core/Axios";
import VueMeta from 'vue-meta'

Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(VueMeta)

Vue.mixin({
    methods: {
        showLoader() {
            this.$store.commit('setStateParam', {
                name: 'loader',
                value: true,
            })
        },
        hideLoader() {
            this.$store.commit('setStateParam', {
                name: 'loader',
                value: false,
            })
        },
        showSnackBar(color, message) {
            this.$store.commit('setStateParam', {
                name: 'snackBar',
                value: {
                    show: true,
                    text: message,
                    color: color ? color : 'success'
                }
            });
        },
        showError(message) {
            if (!message) {
                message = '!!!';
            }
            this.showSnackBar('error', message);
        },
        showSuccess(message) {
            if (!message) {
                message = '^_^';
            }
            this.showSnackBar('success', message);
        },
    },
    computed: {
        ax() {
            return new Axios();
        },
        colorSet() {
            return this.$store.state.leadingColors;
        },
        state() {
            return this.$store.state;
        }
    }
});

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
