<template>
  <v-container v-if="state.loader">
    <v-overlay
        :z-index="zIndex"
        :value="overlay"
    >
      <v-progress-circular
          indeterminate
          :color="colorSet.norbitPrimary"
          size="100"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
export default {
  name: 'LoaderOverlay',
  data: () => ({
    zIndex: 1,
  }),
  computed: {
    overlay() {
      return this.state.loader;
    }
  }
}
</script>
