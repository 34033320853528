import { render, staticRenderFns } from "./TopLoginUserMenu.vue?vue&type=template&id=bb4c3f30&scoped=true&"
import script from "./TopLoginUserMenu.vue?vue&type=script&lang=js&"
export * from "./TopLoginUserMenu.vue?vue&type=script&lang=js&"
import style0 from "./TopLoginUserMenu.vue?vue&type=style&index=0&id=bb4c3f30&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb4c3f30",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VIcon,VList,VListItem,VListItemIcon,VListItemTitle,VMenu})
