<template>
  <v-container :class="{
    'width-1180 main-container pr-5 pl-5 pb-16': true,
    'mt-desktop': !$vuetify.breakpoint.mobile,
    'mt-mobile': $vuetify.breakpoint.mobile
  }">
    <RouterView/>
  </v-container>
</template>

<script>
export default {
  name: "MainContainer"
}
</script>

<style scoped>
.mt-desktop {
margin-top: 330px;
}
.mt-mobile {
  margin-top: 100px;
}
.main-container {
  background-color: #f2f2f3;
  min-height: 1000px;
  position: relative;
  z-index: 1;
}
</style>