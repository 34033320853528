export default {
    computed: {
        resetPassword: function () {
            return this.getTranslationByKey('loginRegisterForm.resetPassword');
        },
        register: function () {
            return this.getTranslationByKey('loginRegisterForm.register');
        },
        buttonLogin: function () {
            return this.getTranslationByKey('loginRegisterForm.buttonLogin');
        },
        buttonCancel: function () {
            return this.getTranslationByKey('loginRegisterForm.buttonCancel');
        },
        buttonRegister: function () {
            return this.getTranslationByKey('loginRegisterForm.buttonRegister');
        },
        buttonResetPassword: function () {
            return this.getTranslationByKey('loginRegisterForm.buttonResetPassword');
        },

        emailInput: function () {
            return this.getTranslationByKey('loginRegisterForm.email');
        },
        repeatEmailInput: function () {
            return this.getTranslationByKey('loginRegisterForm.repeatEmail');
        },
        passwordInput: function () {
            return this.getTranslationByKey('loginRegisterForm.password');
        },
        repeatPasswordInput: function () {
            return this.getTranslationByKey('loginRegisterForm.passwordRepeat');
        },

        emailRules() {
            return [
                v => !!v || this.validators.fieldRequired,
                v => /.+@.+\..+/.test(v) || this.validators.email,
            ];
        },
        requiredField() {
            return [
                v => !!v || this.validators.fieldRequired,
            ];
        },
        repeatEmail() {
            return [
                v => !!v || this.validators.fieldRequired,
                v => v == this.form.email || this.validators.emailRepeat,
                v => /.+@.+\..+/.test(v) || this.validators.email,
            ];
        },
        passwordRules() {
            return [
                v => !!v || this.validators.fieldRequired,
            ];
        },
        passwordRepeatRules() {
            return [
                v => !!v || this.validators.fieldRequired,
                v => v == this.form.password || this.validators.passwordRepeat,
            ];
        },
        validators() {
            return this.getTranslationByKey('validators');
        },
    },
    methods: {
        validate() {
            this.$refs.form.validate()
        },
    }
}
