import { render, staticRenderFns } from "./TopBanersSlider.vue?vue&type=template&id=ae498e2c&scoped=true&"
import script from "./TopBanersSlider.vue?vue&type=script&lang=js&"
export * from "./TopBanersSlider.vue?vue&type=script&lang=js&"
import style0 from "./TopBanersSlider.vue?vue&type=style&index=0&id=ae498e2c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae498e2c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
installComponents(component, {VCard,VIcon,VImg,VSheet,VSlideGroup,VSlideItem})
