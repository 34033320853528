<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" class="mt-5 pa-5">
        <v-card>
          <v-card-title v-html="title"/>
          <v-card-text v-html="articleText"/>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import routeMixin from "@/mixins/routeMixin";

export default {
  name: 'ArticlePage',
  mixins: [routeMixin],
  data: () => ({
    articleText: null,
    title: '',
    lead: ''
  }),
  metaInfo() {
    return {
      title: '',
      titleTemplate: '%s - ' + this.title,
      htmlAttrs: {
        lang: 'pl-PL',
      },
      meta: [
        {charset: 'utf-8'},
        {name: 'description', content: this.lead},
      ]
    }
  },
  mounted() {
    this.getProducts();
  },
  methods: {
    //this.routeParams.article

    getProducts() {
      this.ax.makeCall('GET', 'get-article/' + this.routeParams.article)
          .then((response) => {
            this.title = response.name;
            this.lead = response.lead;
            this.articleText = response.content
          })
    }
  }
}
</script>
