<template>
  <v-col :cols="!!cols ? cols : 7" class="pt-1 text-right">
    <template v-if="!isLogged">
      <v-icon class="login-icon" color="#ffffff">mdi-account-box</v-icon>
      <span class="login-text cursor-pointer" @click="loginRegister">{{ loginText }}</span>
    </template>
    <TopLoginUserMenu v-else/>
    <LoginRegisterResetForm :show-form="showLoginForm" v-on:closeLoginForm="closeLoginRegister"/>
  </v-col>
</template>

<script>
import translationMixin from "@/mixins/translationMixin";
import LoginRegisterResetForm from "@/components/Partials/LoginRegisterResetForm.vue";
import TopLoginUserMenu from "@/components/Partials/TopLoginUserMenu.vue";
import userMixin from "@/mixins/userMixin";

export default {
  name: "TopLogin",
  components: {TopLoginUserMenu, LoginRegisterResetForm},
  props: ['cols'],
  mixins: [translationMixin, userMixin],
  data: () => ({
    showLoginForm: false,
  }),
  computed: {
    token() {
      if (this.$store.state.tkn) {
        return this.$store.state.tkn;
      }
      if (sessionStorage.getItem('tkn')) {
        this.$store.commit('setStateParam', {
          name: 'tkn',
          value: sessionStorage.getItem('tkn'),
        })
        return this.$store.state.tkn;
      }
      return null;
    },
    loggedUser() {
      return this.$store.state.user;
    },
    loginText: function () {
      return this.getTranslationByKey('appBar.loginIn');
    },
    userAccountText: function () {
      return this.getTranslationByKey('appBar.userAccount');
    },
    showLoginResetForm() {
      return this.$store.state.showLoginResetForm;
    },
  },
  watch: {
    showLoginResetForm: {
      immediate: true,
      handler() {
        if (this.showLoginResetForm) {
          this.showLoginForm = true;
        }
      }
    },
    token: {
      immediate: true,
      handler() {
        if (this.token && !this.$store.state.user) {
          this.ax.validateUser();
        }
      }
    },
  },
  methods: {
    loginRegister() {
      this.showLoginForm = true;
    },
    closeLoginRegister() {
      this.$store.commit('setStateParam', {
        name: 'user',
        value: null
      })
      this.$store.commit('setStateParam', {
        name: 'showLoginResetForm',
        value: null
      })
      this.showLoginForm = false;
    },
    logoutUser() {
      this.$store.commit('setStateParam', {
        name: 'user',
        value: null,
      });
      this.$store.commit('setStateParam', {
        name: 'tkn',
        value: null,
      });
      sessionStorage.removeItem('tkn');
      sessionStorage.removeItem('user');
    }
  }
}
</script>

<style scoped>
.login-icon {
  margin-top: -3px;
  color: #ffffff;
}

.login-text {
  color: #ffffff;
}
</style>