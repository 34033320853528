<template>
  <v-sheet
      class="mx-auto background-transparent-important overflow-hidden mt-5"
  >
    <v-card class="banner-card-bg px-1 pt-1" color="#f7f7f7" flat tile v-if="banners.length > 0">
      <v-img :src="prepareImageUrl(randomBanner.file)" class="cursor-pointer banner-image"
             @click="makeClick(randomBanner)" v-if="randomBanner.yt_player===0"/>
      <video width="275" height="275" controls v-else>
        <source :src="prepareImageUrl(randomBanner.file)" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </v-card>
  </v-sheet>
</template>

<script>
import bannerMixin from "@/mixins/bannerMixin";

export default {
  //https://drive.google.com/drive/folders/171jpwc7myRhdO0k3oWc9YXIzdtdB-BVy
  name: "SquareBanner",
  mixins: [bannerMixin],
  data: () => ({
    currentBanner: 0,
    banners: [],
    randomBanner: null,
  }),
  mounted() {
    this.getBannersToShow(2);
  },
}
</script>

<style scoped>
.arrow-left {
  position: absolute;
  left: 9px;
}

.arrow-right {
  position: absolute;
  right: 4px;
}

.banner-card-bg {
  width: 275px;
  height: 275px
}

.banner-image {
  width: 275px;
  height: 275px;
}
</style>