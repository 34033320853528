<template>
  <v-dialog
      v-model="dialog"
      max-width="600"
      persistent
      v-if="$store.state.user && $store.state.user.id"
  >
    <v-card style="overflow-x: hidden">
      <v-row no-gutters class="pt-1">
        <v-col cols="1" offset="11" class="text-center">
          <v-icon class="cursor-pointer" @click="closeProfile">
            mdi-close-circle-outline
          </v-icon>
        </v-col>
      </v-row>
      <v-card-title class="text-h5" v-if="!userOpenOwnSettings" v-html="noAccessText"/>
      <template v-else>
        <v-card-title class="text-h5" v-html="titleText"/>
      </template>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-divider/>
          </v-col>
        </v-row>
        <v-expansion-panels focusable v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header v-html="userProfileTexts.title"/>
            <v-expansion-panel-content>
              <v-row justify="space-around">
                <v-col cols="11">
                  <v-text-field v-model="customer.basic_data.name" :label="userProfileTexts.name" clearable/>
                </v-col>
                <v-col cols="11">
                  <v-text-field v-model="customer.basic_data.first_name" :label="userProfileTexts.firstName" clearable/>
                </v-col>
                <v-col cols="11">
                  <v-text-field v-model="customer.basic_data.last_name" :label="userProfileTexts.lastName" clearable/>
                </v-col>
                <v-col cols="11">
                  <v-text-field v-model="customer.basic_data.phone" :label="userProfileTexts.phone" clearable/>
                </v-col>
                <v-col cols="11" class="text-right">
                  <v-btn
                      class="ma-2"
                      outlined
                      :color="colorSet.norbitPrimary"
                      tile
                      :loading="loading"
                      @click="saveCustomerBasicData"
                  >
                    <v-icon>mdi-content-save-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header v-html="addressDataTexts.title"/>
            <v-expansion-panel-content>
              <CustomerAddressForm :customer="customer"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header v-html="changePasswordTexts.title"/>
            <v-expansion-panel-content>
              <CustomerChangePasswordForm :customer="customer"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import userMixin from "@/mixins/userMixin";
import translationMixin from "@/mixins/translationMixin";
import CustomerAddressForm from "@/components/Partials/CustomerAddressForm.vue";
import CustomerChangePasswordForm from "@/components/Partials/CustomerChangePasswordForm.vue";

export default {
  name: "CustomerProfile",
  components: {CustomerChangePasswordForm, CustomerAddressForm},
  mixins: [userMixin, translationMixin],
  data: () => ({
    panel: 0,
    dialog: true,
    loading: false
  }),
  mounted() {
    this.redirectIfNotLogged();
  },
  methods: {
    redirectIfNotLogged() {
      if (!this.isLogged) {
        this.$router.push({name: 'home'});
      }
    },
    closeProfile() {
      this.$router.push({name: 'home'});
    },
    saveCustomerBasicData() {
      this.customer.customer_id = this.$store.state.user.id;
      this.loading = true;
      this.ax.makeCall('PUT', 'customer/' + this.$route.params.id, this.customer.basic_data, true)
          .then(() => {
            this.loading = false;
          });
    }
  },
  computed: {
    customer: {
      get: function () {
        return this.$store.state.user;
      },
      set: function (newValue) {
        return newValue
      }
    },
    userOpenOwnSettings() {
      return this.$store.state.user && this.$route.params.id == this.$store.state.user.id;
    },
    noAccessText() {
      return this.getTranslationByKey('errors.noAccess');
    },
    titleText() {
      return this.getTranslationByKey('userProfile.title');
    },
    userProfileTexts() {
      return this.getTranslationByKey('userProfile.basicData');
    },
    addressDataTexts() {
      return this.getTranslationByKey('userProfile.addressData');
    },
    changePasswordTexts() {
      return this.getTranslationByKey('userProfile.changePassword');
    }
  }
}
</script>

<style scoped>

</style>