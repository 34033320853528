<template>
  <div class="position-absolute center-vertical width-100-percent">
    <v-row no-gutters justify="center">
      <v-col cols="8" v-html="promoPriceText"
             :class="{
                        'text-center mt-1 promo-price-text': true,
                        'promo-price-text-product-page': productPage
                       }"
      />
    </v-row>
    <v-row no-gutters justify="center">
      <v-col :cols="productPage ? 6 : 12" lg="10">
        <v-row no-gutters justify="center">
          <v-col :cols="productPage ? 7 : 9" v-html="Math.trunc(productPrice)"
                 :class="{
                        'text-right promo-price-int': true,
                        'promo-price-int-product-page': productPage,
                       }"

          />
          <v-col cols="3"
                 :class="{
                        'promo-price-dec': true,
                        'promo-price-dec-product-page': true,
                       }"
          >
            <v-row no-gutters :class="{
              'decimal-part-margin': true
            }">
              <v-col cols="12" :class="{
                'pa-0 promo-price-dec mt-1': true,
                'promo-price-dec-product-page mt-2': productPage
              }" v-html="decimalPartOfPrice"/>
              <v-col cols="12" :class="{
                'pa-0 promo-price-dec promo-price-dec-currency ma-0': true,
                'promo-price-dec-product-page': productPage
              }" v-html="currency"/>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="productPage">
      <ProductBasketButton :product="product" :platform="platform" :category="category"/>
    </v-row>
  </div>
</template>

<script>
import translationMixin from "@/mixins/translationMixin";
import imageMixin from "@/mixins/imageMixin";
import ProductBasketButton from "@/components/Partials/ProductBasketButton.vue";
import productMixin from "@/mixins/productMixin";

export default {
  name: "ProductPricePart",
  components: {ProductBasketButton},
  props: ['product', 'productPage', 'platform', 'category'],
  mixins: [translationMixin, imageMixin, productMixin],
  data: () => ({
    showSkeleton: false,
  }),
  methods: {},
  computed: {
    decimalPartOfPrice() {
      const price = this.productPrice.toFixed(2);
      return (price + '').split('.')[1];
    },
    promoPriceText: function () {
      return this.getTranslationByKey('productModule.promoPrice');
    },
    lowestPriceText: function () {
      return this.getTranslationByKey('productModule.lowestPriceWithin30days');
    },
    basketText: function () {
      return this.getTranslationByKey('productModule.toBasket');
    },
    currency: function () {
      return this.getTranslationByKey('app.currency');
    },
  }
}
</script>

<style scoped>
.promo-price-dec-currency {
  margin-top: -12px !important;
}

.promo-price-text {
  font-size: 17px;
  color: #0084cc
}

.promo-price-text-product-page {
  font-size: 20px;
}

.promo-price-int {
  color: #0084cc;
  font-size: 34px;
  font-weight: bold;
}

.promo-price-int-product-page {
  font-size: 42px;
}

.promo-price-dec {
  color: #0084cc;
  font-size: 16px;
  font-weight: bold;
}

.promo-price-dec-product-page {
  font-size: 19px;
}

.decimal-part-margin {
  margin-top: 2px;
}

.decimal-part-margin-product-page {
  margin-top: 2px;
}
</style>