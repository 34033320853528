import { render, staticRenderFns } from "./ProductsModule.vue?vue&type=template&id=520f6b31&scoped=true&"
import script from "./ProductsModule.vue?vue&type=script&lang=js&"
export * from "./ProductsModule.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/productModule.css?vue&type=style&index=0&id=520f6b31&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "520f6b31",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VCol,VIcon,VRow,VSheet})
