<template>
  <v-col
      :cols="!!cols ? cols : 5"
      class="pt-1 text-right cursor-pointer"
      @click="goToBasket">
    <v-icon class="login-icon" color="#ffffff">mdi-cart-outline</v-icon>
    <span class="login-text">{{ basketValue.toFixed(2) }} {{ currencyText }}</span>
  </v-col>
</template>

<script>
import translationMixin from "@/mixins/translationMixin";
import basketMixin from "@/mixins/basketMixin";

export default {
  name: "TopBasket",
  props: ['cols'],
  mixins: [translationMixin, basketMixin],
  computed: {
    currencyText: function () {
      return this.getTranslationByKey('app.currency');
    },
  },
  methods: {
    goToBasket() {
      if(this.$store.state.basket.length > 0) {
        this.$router.push({name: 'basket'})
      }
    }
  }
}
</script>

<style scoped>
.login-icon {
  margin-top: -3px;
  color: #ffffff;
}

.login-text {
  color: #ffffff;
}
</style>