<template>
  <div>
    404
  </div>
</template>

<script>
import translationMixin from "@/mixins/translationMixin";

export default {
  name: 'NotFoundPage',
  mixins: [translationMixin],
  data: () => ({}),
}
</script>

<style scoped>

</style>