<template>
  <v-sheet color="transparent" class="pa-1" v-if="productsList.length > 0">
    <v-row no-gutters>
      <v-col cols="12" v-html="title" class="text-center module-title mb-0 pb-0 text-uppercase"
             :style="{color: fontColor}"/>
      <v-col cols="12" class="mt-0 position-relative" :style="moduleBarStyle">
        <v-icon class="position-absolute module-down-arrow" :style="{color: fontColor}">mdi-menu-down</v-icon>
      </v-col>
    </v-row>
    <v-row v-if="productsList.length > 0" no-gutters>
      <template v-for="(product, index) in productsList">
        <v-col cols="6" :sm="colsNumber" :key="'product_'+index"
               :class="{
        'pr-1':isGadget ? index%2!==0 : index%2===0,
        'pl-1':isGadget ? index%2===0 : index%2!==0,
        'mt-2': true,
      }" v-if="(showSquare && index <= 2) || !showSquare"
        >
          <ProductTile :product="product"/>
        </v-col>
      </template>
      <SquareBanner v-if="showSquare"/>
    </v-row>
  </v-sheet>
</template>

<script>
import translationMixin from "@/mixins/translationMixin";
import ProductTile from "@/components/Partials/ProductTile.vue";
import SquareBanner from "@/components/Partials/SquareBanners.vue";

export default {
  name: "ProductsModule",
  components: {SquareBanner, ProductTile},
  props: ['moduleData', 'isGadget', 'showSquare', 'showFullRow'],
  mixins: [translationMixin],
  computed: {
    colsNumber: function () {
      if (this.isGadget || this.showFullRow) {
        return 3;
      }
      return 6;
    },
    productsList: function () {
      if (this.moduleData && this.moduleData.products) {
        return this.moduleData.products;
      }
      return [];
    },
    fontColor: function () {
      if (this.moduleData) {
        return this.getTranslationByKey(this.moduleData.colorKey);
      }
      return 'transparent';
    },
    moduleBarStyle: function () {
      return {
        color: this.fontColor,
        borderBottom: '2px solid ' + this.fontColor,
      }
    },
    title: function () {
      if (this.moduleData) {
        return this.getTranslationByKey(this.moduleData.titleKey);
      }
      return '';
    },
  }
}
</script>

<style scoped src="@/assets/css/productModule.css">

</style>