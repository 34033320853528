import Vue from 'vue'
import Vuex from 'vuex'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        config: null,
        loader: false,
        lang: 'pl',
        snackBar: {
            show: false,
            text: '',
            color: 'error'
        },
        color: 'primary',
        translations: null,
        leadingColors: {
            primary: colors.lightBlue.darken3,
            secondary: colors.brown.lighten3,
            accent: colors.blueGrey.lighten2,
            error: colors.red.darken2,
            norbitPrimary: '#0084cc',
            norbitSecondary: '#1f9fe9'
        },
        user: null,
        showLoginResetForm: null,
        basket: [],
        basketValue: 0,
        tkn: null,
        order: {
            delivery: {
                gross: 0,
            }
        },
        showFilters: false,
    },
    getters: {},
    mutations: {
        setStateParam(state, payload) {
            state[payload.name] = payload.value;
        },
    },
    actions: {},
    modules: {}
})
