export default {
    methods: {
        getBannersToShow(position) {
            this.ax.makeCall('GET', 'banner/show-by-position/' + position)
                .then(response => {
                    this.banners = response;
                });
        },
        makeClick(banner) {
            this.ax.makeCall('POST', 'banner/register-click', {
                id: banner.id
            })
                .then(() => {
                    if (banner.external_window === 1) {
                        window.open(banner.url, '_blank');
                    } else {
                        window.location.replace(banner.url);
                    }
                });
        },
        prepareImageUrl(imageUrl) {
            return process.env.VUE_APP_API_URL + '/' + imageUrl.replace('public', 'storage');
        },

    },
    watch: {
        banners: {
            handler() {
                this.randomBanner = this.banners[Math.floor(Math.random()*this.banners.length)];
            },
            deep: true,
        }
    },
}
