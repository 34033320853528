export default {
    methods: {
        setBasket() {
            if (this.$store.state.basket.length === 0 && sessionStorage.getItem('basket')) {
                this.$store.commit('setStateParam', {
                    name: 'basket',
                    value: JSON.parse(sessionStorage.getItem('basket')),
                })
            }
        },
        modifyItemInBasket(product, quantity) {
            this.ax.makeCall('GET', 'product/' + product.id + '/check-stock')
                .then((response) => {
                    if (quantity < 0 || (response - quantity - product.quantity) >= 0) {
                        let basket = sessionStorage.getItem('basket');
                        if (!basket) {
                            basket = [];
                        } else {
                            basket = JSON.parse(basket);
                        }

                        let itemIndexInBasket = Object.values(basket).findIndex(item =>
                            item.id === product.id
                            && item.slugged_name === product.slugged_name
                            && item.price === product.price
                        )

                        if (itemIndexInBasket > -1) {
                            basket[itemIndexInBasket].quantity += quantity;
                        }
                        if (basket[itemIndexInBasket].quantity <= 0) {
                            basket.splice(itemIndexInBasket, 1);
                        }

                        this.$store.commit('setStateParam', {
                            name: 'basket',
                            value: basket,
                        })
                        sessionStorage.setItem('basket', JSON.stringify(basket));
                        if (basket.length === 0) {
                            this.$router.push({name: 'home'});
                        }
                    } else {
                        this.showError(this.notEnoughStockText);
                    }

                })

        },
        addProductToBasket(quantity) {
            let basket = sessionStorage.getItem('basket');
            if (!basket) {
                basket = [];
            } else {
                basket = JSON.parse(basket);
            }

            const productToBasket = {
                id: this.product.id,
                slugged_name: this.product.slugged_name,
                title: this.product.title,
                price: this.productPrice,
                quantity: quantity,
                category: this.category,
                platform: this.platform,
            }
            let itemIndexInBasket = Object.values(basket).findIndex(item =>
                item.id === this.product.id
                && item.slugged_name === this.product.slugged_name
                && item.price === this.productPrice
            )
            if (itemIndexInBasket === -1) {
                basket.push(productToBasket)
            } else {
                basket[itemIndexInBasket].quantity += quantity;
            }
            this.$store.commit('setStateParam', {
                name: 'basket',
                value: basket,
            })
            sessionStorage.setItem('basket', JSON.stringify(basket));
        },
    },
    computed: {
        basketValue: function () {
            this.setBasket();
            const basketStore = this.$store.state.basket;
            let basketValue = 0;
            basketStore.forEach((item) => {
                basketValue += item.quantity * item.price;
            })
            return basketValue;
        },
        notEnoughStockText: function () {
            return this.getTranslationByKey('basket.notEnoughStock');
        },
    }
}
