<template>
  <v-row>

  </v-row>
</template>

<script>
import translationMixin from "@/mixins/translationMixin";
import routeMixin from "@/mixins/routeMixin";

export default {
  name: 'ActivationPage',
  mixins: [translationMixin, routeMixin],
  data: () => ({}),
  watch: {
    uuid: {
      immediate: true,
      handler() {
        if (this.uuid) {
          this.activateUser();
        }
      },
    },
  },
  mounted() {

  },
  methods: {
    activateUser() {
      this.ax.makeCall('POST', 'customer/activate', {uuid: this.uuid})
          .then((response) => {
            this.showSuccess(response);
          })
    }
  },
  computed: {
    uuid() {
      return this.$route.params.uuid;
    }
  }
}
</script>

<style scoped>

</style>