<template>
  <div>
    <v-menu offset-y tile>
      <template v-slot:activator="{ on, attrs }">
        <v-icon class="login-icon" color="#ffffff"
                v-bind="attrs"
                v-on="on"
        >mdi-account-box
        </v-icon>
        <span
            class="login-text cursor-pointer"
            v-bind="attrs"
            v-on="on"
        >
        {{ userAccountText }}
      </span>
      </template>
      <v-list dense>
        <v-list-item class="cursor-pointer" @click="openUserProfile">
          <v-list-item-icon>
            <v-icon>mdi-account-edit</v-icon>
          </v-list-item-icon>
          <v-list-item-title v-html="userProfileText"/>
        </v-list-item>
        <v-list-item class="cursor-pointer" @click="openHistory">
          <v-list-item-icon>
            <v-icon>mdi-order-bool-descending-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-title v-html="orderHistoryText"/>
        </v-list-item>
        <v-list-item class="cursor-pointer" @click="openConfirm">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title v-html="logoutText"/>
        </v-list-item>
      </v-list>
    </v-menu>
    <DialogConfirm
        :showConfirmQuestion="showConfirmQuestion"
        :question="logoutQuestion"
        v-on:confirm="logoutUser"
        v-on:cancel="showConfirmQuestion=false"
    />
  </div>
</template>

<script>
import translationMixin from "@/mixins/translationMixin";
import DialogConfirm from "@/components/Partials/DialogConfirm.vue";

export default {
  name: "TopLoginUserMenu",
  components: {DialogConfirm},
  mixins: [translationMixin],
  data: () => ({
    showConfirmQuestion: false,
  }),
  computed: {
    userAccountText: function () {
      return this.getTranslationByKey('appBar.userAccount');
    },
    userProfileText: function () {
      return this.getTranslationByKey('appBar.userProfile');
    },
    orderHistoryText: function () {
      return this.getTranslationByKey('appBar.orderHistory');
    },
    logoutText: function () {
      return this.getTranslationByKey('appBar.logout');
    },
    logoutQuestion: function () {
      return this.getTranslationByKey('appBar.logoutQuestion');
    },
  },
  methods: {
    openHistory() {
      if (this.$route.name !== 'customerOrderHistory')
        this.$router.push({
          name: 'customerOrderHistory', params: {
            id: this.$store.state.user.id
          }
        });
    },
    openUserProfile() {
      if (this.$route.name !== 'customerProfile')
        this.$router.push({
          name: 'customerProfile', params: {
            id: this.$store.state.user.id
          }
        });
    },
    openConfirm() {
      this.showConfirmQuestion = true;
    },
    logoutUser() {
      this.$store.commit('setStateParam', {
        name: 'user',
        value: null,
      });
      this.$store.commit('setStateParam', {
        name: 'tkn',
        value: null,
      });
      sessionStorage.removeItem('tkn');
      sessionStorage.removeItem('user');
    }
  }
}
</script>

<style scoped>
.login-icon {
  margin-top: -3px;
  color: #ffffff;
}

.login-text {
  color: #ffffff;
}
</style>