export default {
    computed: {
        productPrice() {
            if (this.product.promoPrice) {
                return this.product.promoPrice;
            }
            return this.product.price;
        },
    }
}
