<template>
  <v-row no-gutters dense>
    <script type="application/javascript">
      function afterPointSelected(point) {
        var myEvent = new CustomEvent("myCustomEvent", {
          detail: point,
        });
        document.dispatchEvent(myEvent);
      }
    </script>
    <v-col cols="12">
      <v-radio-group
          v-model="deliveryType"
      >
        <v-radio
            v-for="(delivery, index) in deliveries" :key="'delivery_type'+index"
            :label="delivery.name + ' (' + delivery.gross + currency + ')'"
            :value="delivery.id"
            @click="updateOrderDelivery"
        ></v-radio>
      </v-radio-group>
    </v-col>
    <v-col cols="12" v-if="deliveryType===1">
      {{ inpostSelectedTxt }} {{ inpostData ? inpostData.name : '' }}
    </v-col>
    <v-col cols="12" v-if="deliveryType===1" class="inpost-widget">
      <inpost-geowidget id='3' onpoint="afterPointSelected"
                        token='eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJkVzROZW9TeXk0OHpCOHg4emdZX2t5dFNiWHY3blZ0eFVGVFpzWV9TUFA4In0.eyJleHAiOjIwMTA3NDUwNzUsImlhdCI6MTY5NTM4NTA3NSwianRpIjoiNzIyZDIxYjgtMTk0Mi00NTI2LWFiMGQtMjIyMTA2YjBhNzg1IiwiaXNzIjoiaHR0cHM6Ly9zYW5kYm94LWxvZ2luLmlucG9zdC5wbC9hdXRoL3JlYWxtcy9leHRlcm5hbCIsInN1YiI6ImY6N2ZiZjQxYmEtYTEzZC00MGQzLTk1ZjYtOThhMmIxYmFlNjdiOkkzZ2NxeUwxbVJvdFE1aHJHZ3BmOEx6OW9JZ2ZKT204T1VhLVFFdVZiX0EiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJzaGlweCIsInNlc3Npb25fc3RhdGUiOiJhZDE2YzJhMS04YTk5LTRmMTQtYTk2NC0zODkzNmJhNDY4MzQiLCJzY29wZSI6Im9wZW5pZCBhcGk6YXBpcG9pbnRzIiwic2lkIjoiYWQxNmMyYTEtOGE5OS00ZjE0LWE5NjQtMzg5MzZiYTQ2ODM0IiwiYWxsb3dlZF9yZWZlcnJlcnMiOiIqLmFrbWllY2lrLmNvbSIsInV1aWQiOiI5MmYyNWQ0ZS00ZDEyLTQ5ZWYtYjNhMy1mNjVlNDc0NjcyMmMifQ.Reopucrxgj9fZAj4gHQeia4FezQKFrL2IoCV1seSj35poNcqnOlXlfwiXn5FpJWvFp81FuQAzJq9l1ArLCatZloAfI9QqDA1Qiabq7XWMf8TQX6KAKM_IN-63_xqtORISRThJVxonjdK9p1DVEMKdJlApm8bz4Lqlymabl_7TsaNnI-fFVdk12Ks49UNq7ADAn6SVL9MZq7McCtUtJxTITXpCnP4AzIKvppQ3GZQo4VPotEm2Gw2xdlkwq2YZCjHkXWURWpeS-pMK06qL03jCUkDFJ1X5_W9mKtJ7KNC2pT5OfYFon-fTVydKf-u6Y7109mVtW_8dRyWODhZdI5plw'
                        language='pl' config='parcelCollect'></inpost-geowidget>
    </v-col>
    <PlaceOrderNextPrevButtons :show-prev="true" :show-next="!!deliveryType"
                               v-on:prevStep="$emit('prevStep')"
                               v-on:nextStep="$emit('nextStep')"
    />
  </v-row>
</template>

<script>
import translationMixin from "@/mixins/translationMixin";
import PlaceOrderNextPrevButtons from "@/components/Partials/PlaceOrderNextPrevButtons.vue";

export default {
  name: "PlaceOrderDelivery",
  components: {PlaceOrderNextPrevButtons},
  mixins: [translationMixin],
  data: () => ({
    deliveryType: null,
    deliveries: [],
    inpostData: null,
  }),
  metaInfo() {
    return {
      link: [
        {
          rel: "stylesheet",
          href: "https://sandbox-easy-geowidget-sdk.easypack24.net/inpost-geowidget.css"
        }
      ],
      script: [
        {src: 'https://sandbox-easy-geowidget-sdk.easypack24.net/inpost-geowidget.js', async: true, defer: true}
      ],
    }
  },
  mounted() {
    this.getDeliveries();
    document.addEventListener("myCustomEvent", (event) => {
      this.inpostData = event.detail;
      this.updateOrderDelivery();
    });
  },
  methods: {
    updateOrderDelivery() {
      const selectedDelivery = this.deliveries.filter(delivery => delivery.id === this.deliveryType);
      if (selectedDelivery.length > 0) {
        let order = this.$store.state.order;
        if (order) {
          if (this.inpostData && this.deliveryType === 1) {
            selectedDelivery[0].description = this.inpostData.name + ', ' + this.inpostData.address.line1 + ', ' + this.inpostData.address.line2;
          }
          order = Object.assign(order, {delivery: selectedDelivery[0]});
        } else {
          order = {delivery: selectedDelivery[0]};
        }
        this.$store.commit('setStateParam', {
          name: 'order',
          value: order
        })
      }
    },
    getDeliveries() {
      this.ax.makeCall('GET', 'deliveries')
          .then((response) => {
            this.deliveries = response;
          });
    }
  },
  computed: {
    currency: function () {
      return this.getTranslationByKey('app.currency');
    },
    inpostSelectedTxt: function () {
      return this.getTranslationByKey('order.inpostSelected');
    },
  }
}
</script>

<style scoped>
.inpost-widget {
  height: 500px;
}
</style>