<template>
  <v-row class="mt-5">
    <v-col cols="6" sm="3">
      <v-select
          v-model="sortValue"
          :items="sorterList"
          item-text="text"
          item-value="value"
          :item-color="colorSet.norbitPrimary"
          :label="sortText"
          append-icon="mdi-sort"
          :rounded="false"
          dense
          @change="changeSort"
      ></v-select>
    </v-col>
    <v-col sm="5" class="hidden-xs-only"></v-col>
    <v-col cols="6" sm="4" class="text-right">
      <v-btn outlined tile :color="colorSet.norbitPrimary" @click="toggleFilters">
        <v-icon>mdi-filter-outline</v-icon>
        {{ filterButtonText }}
      </v-btn>
    </v-col>
    <v-col cols="12">
      <ProductFilters/>
    </v-col>
    <v-col cols="6" sm="3" v-for="(product, index) in products" :key="product.slugged_name + index">
      <ProductTile :product="product"/>
    </v-col>
    <ProductsLoadMoreButton :page="page" :totalPages="totalPages" v-on:loadMoreProducts="loadMoreProducts"/>
  </v-row>
</template>

<script>
import translationMixin from "@/mixins/translationMixin";
import ProductTile from "@/components/Partials/ProductTile.vue";
import ProductsLoadMoreButton from "@/components/Partials/ProductsLoadMoreButton.vue";
import routeMixin from "@/mixins/routeMixin";
import ProductFilters from "@/components/Partials/ProductFilters.vue";
import filterMixin from "@/mixins/filterMixin";

export default {
  name: 'SearchResultPage',
  components: {ProductFilters, ProductsLoadMoreButton, ProductTile},
  mixins: [translationMixin, routeMixin, filterMixin],
  data: () => ({
    page: 1,
    products: [],
    totalPages: 1,
    orderBy: 'name',
    orderDir: 'ASC',
    sortValue: 'nameAsc',
    endpointUrl: null,
    queryValue: null,
  }),
  watch: {
    platform: {
      immediate: true,
      handler() {
        if (this.platform) {
          this.endpointUrl = 'product/platform';
          this.queryValue = this.platform;
          this.getProducts();
        }
      }
    },
    category: {
      immediate: true,
      handler() {
        if (this.category) {
          this.getProducts();
        }
      }
    },
    search: {
      immediate: true,
      handler() {
        if (this.search) {
          this.queryValue = JSON.stringify(this.getQuery());
          this.endpointUrl = 'product/search';
          this.getProducts();
        }
      }
    },
    routeQuery: {
      immediate: true,
      handler() {
        this.queryValue = JSON.stringify(this.getQuery());
        this.getProducts();
      }
    },
  },
  computed: {
    route() {
      return this.$route.name;
    },
    routeParams() {
      return this.$route.params;
    },
    platform() {
      return this.$route.params.platform;
    },
    category() {
      return this.$route.params.category;
    },
    search() {
      return this.$route.name === 'filter';
    },
    sortText: function () {
      return this.getTranslationByKey('searchPage.sortText');
    },
    sorterList: function () {
      return this.getTranslationByKey('searchPage.sorting');
    },
  },
  methods: {
    changeSort(e) {
      if (e === 'priceAsc') {
        this.orderBy = 'price';
        this.orderDir = 'ASC';
      }
      if (e === 'priceDesc') {
        this.orderBy = 'price';
        this.orderDir = 'DESC';
      }
      if (e === 'nameAsc') {
        this.orderBy = 'name';
        this.orderDir = 'ASC';
      }
      if (e === 'nameDesc') {
        this.orderBy = 'name';
        this.orderDir = 'DESC';
      }
      this.page = 1;
      if (this.platform) {
        this.queryValue = this.platform;
      }
      this.getProducts();
    },
    loadMoreProducts() {
      this.page++;
      this.getProducts(true);
    },
    getProducts(addToList = false) {
      this.showLoader();
      this.ax.makeCall('POST', this.endpointUrl, {
        route: this.route,
        routeParams: this.routeParams,
        page: this.page,
        value: JSON.stringify(this.getQuery()),
        orderBy: this.orderBy,
        orderDir: this.orderDir
      }).then((response) => {
        if (addToList) {
          this.products = this.products.concat(response.products);
        } else {
          this.products = response.products;
        }
        this.totalPages = response.totalPages;
        this.hideLoader();
      })
    },
  }
}
</script>

<style scoped>

</style>