export default {
    methods: {
        getQuery() {
            return this.$route.query;
        }
    },
    computed: {
        routeQuery() {
            return this.getQuery();
        },
        routeParams() {
            return this.$route.params;
        }
    }
}
