<template>
  <v-card>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
    >
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field clearable :label="userProfileTexts.name" v-model="address.basic_data.name"/>
            <v-text-field clearable :label="userProfileTexts.firstName" v-model="address.basic_data.first_name" :rules="requiredField"/>
            <v-text-field clearable :label="userProfileTexts.lastName" v-model="address.basic_data.last_name" :rules="requiredField"/>
            <v-text-field clearable :label="userProfileTexts.email" v-model="address.email" :rules="emailRules"/>
            <v-text-field clearable :label="userProfileTexts.phone" v-model="address.basic_data.phone"
                          :rules="requiredField"/>
          </v-col>
          <v-col cols="12">
            <v-text-field clearable :label="addressDataTexts.address" v-model="address.address_data.address"
                          :rules="requiredField"/>
            <v-text-field clearable :label="addressDataTexts.houseNo"
                          v-model="address.address_data.house_number" :rules="requiredField"/>
            <v-text-field clearable :label="addressDataTexts.flatNo" v-model="address.address_data.flat_number"/>
            <v-text-field clearable :label="addressDataTexts.zip" v-model="address.address_data.zip"
                          :rules="requiredField"/>
            <v-text-field clearable :label="addressDataTexts.city" v-model="address.address_data.city"
                          :rules="requiredField"/>
            <v-text-field clearable :label="addressDataTexts.country" v-model="address.address_data.country"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
    <v-card-actions>
      <PlaceOrderNextPrevButtons :show-prev="true" :show-next="true"
                                 v-on:prevStep="$emit('prevStep')"
                                 v-on:nextStep="nextStep"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import translationMixin from "@/mixins/translationMixin";
import userMixin from "@/mixins/userMixin";
import PlaceOrderNextPrevButtons from "@/components/Partials/PlaceOrderNextPrevButtons.vue";
import loginRegisterResetMixin from "@/mixins/loginRegisterResetMixin";

export default {
  name: "PlaceOrderAddress",
  components: {PlaceOrderNextPrevButtons},
  mixins: [translationMixin, userMixin, loginRegisterResetMixin],
  data: () => ({
    valid: true,
    address: {
      email: null,
      phone: null,
      basic_data: {
        first_name: null,
        last_name: null,
        name: null,
        customer_id: null,
      },
      address_data: {
        address: null,
        house_number: null,
        flat_number: null,
        zip: null,
        country: 'Polska',
      },
    }
  }),
  mounted() {
    this.validate();
  },
  watch: {
    isLogged: {
      immediate: true,
      handler() {
        if (this.isLogged) {
          this.address = Object.assign(this.address, this.$store.state.user);
        }
      }
    }
  },
  computed: {
    userProfileTexts() {
      return this.getTranslationByKey('userProfile.basicData');
    },
    addressDataTexts() {
      return this.getTranslationByKey('userProfile.addressData');
    },
  },
  methods: {
    nextStep() {
      this.validate();
      if (this.valid) {
        this.$store.commit('setStateParam', {
          name: 'order',
          value: this.$store.state.order ? Object.assign(this.$store.state.order, {address: this.address}) : this.address
        })
        this.$emit('nextStep');
      }
    }
  }
}
</script>

<style scoped>

</style>