<template>
  <div>
    <v-card-text>
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
      >
        <v-text-field
            v-model="form.email"
            :rules="emailRules"
            :label="emailInput"
        />
        <v-text-field
            v-model="form.password"
            :rules="passwordRules"
            :label="passwordInput"
            :type="'password'"
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
          class="ma-2"
          :color="colorSet.norbitPrimary"
          tile
          v-html="buttonLogin"
          @click="loginUser"
      />
      <v-btn
          class="ma-2"
          outlined
          :color="colorSet.norbitPrimary"
          tile
          v-html="buttonCancel"
          @click="$emit('closeForm')"
      />
    </v-card-actions>
  </div>
</template>

<script>
import loginRegisterResetMixin from "@/mixins/loginRegisterResetMixin";
import translationMixin from "@/mixins/translationMixin";

export default {
  name: "LoginForm",
  mixins: [translationMixin, loginRegisterResetMixin],
  data: () => ({
    valid: true,
    form: {
      email: null,
      password: null,
    }
  }),
  methods: {
    loginUser() {
      this.showLoader();
      this.ax.makeAuthCall(this.form)
          .then((response) => {
            sessionStorage.removeItem('tkn');
            sessionStorage.setItem('tkn', response.access_token);
            this.$store.commit('setStateParam', {
              name: 'tkn',
              value: response.access_token
            })
            this.hideLoader();
            this.$emit('closeForm');
          })
          .catch(() => {
            this.showError(this.loginErrorMessage)
          });
    }
  },
  computed: {
    loginErrorMessage: function () {
      return this.getTranslationByKey('errors.loginError');
    },
  }
}
</script>

<style scoped>

</style>